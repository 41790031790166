import { Col, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function OffersSlider({ title, slides }) {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
  };

  console.log("OffersSlider", slides);

  return (
    <Slider {...settings} className="offer-slider">
      {slides.map((slide, index) => (
        <>
          <div
            style={{
              backgroundImage: `url(${slide.image_url})`,
              // backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              height: "100%",
              position: "relative",
            }}
          >
            <Link
              style={{ position: "absolute", bottom: "10%", left: "3%" }}
              to="/offers"
            >
              {slide.btnText ? slide.btnText : "Book now"}{" "}
              <img src="assets/arrow-right-lrg.svg" alt="" />
            </Link>
          </div>
        </>
      ))}
    </Slider>
  );
}
