import axios from "axios";
import { toast } from "react-toastify";

function handleResponse(res) {
    console.log(res);
}
let token = localStorage.getItem("dentistUserToken");
const auth = {
    "content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
}

export async function getDoctor() {
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = { method: "GET", headers: auth };
    return fetch(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/doctor`,
        requestOptions
    )
}

export const doctorDashBoardService = {
    getDoctor
};
