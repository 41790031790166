import { Card, Col } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { langContext } from "../../context/LangProvider";

export default function Offer({ offer }) {
  const [image, setImage] = useState("/assets/arrow-circle-right-sml.svg");
  const { doctors } = useSelector((state) => state.doctors);
  const [doctor, setDoctor] = useState(null);
  useEffect(() => {
    if (doctors) {
      const doctor = doctors.find((doctor) => doctor.id === offer.doctorId);
      setDoctor(doctor?.name);
    } else {
      setDoctor("");
    }
  }, [doctors]);
  const { t } = useContext(langContext);
  return (
    <Col sm={12} md={6} lg={4} className="offersPadding">
      <Card className="spec-card offersPage card-shadow">
        <div className="img-cont">
          <Card.Img className="top-img offer" src={offer.image} alt="" />
        </div>
        <Card.Body>
          <Card.Title className="mb-4 bold">
            {offer.name || "Offers"}
          </Card.Title>
          <div>{offer.offer}</div>
          <div className="my-1">
            <img
              src="/assets/doctor-name.svg"
              alt=""
              className="icon-img-size"
            />
            <span className="ms-2 bold">{doctor}</span>
          </div>
          <div className="my-1">
            <img src="/assets/fees.svg" alt="" className="icon-img-size" />
            <span className="ms-2 bold mainColor">
              {t("EGP")} {offer.fees.toFixed(2)}
            </span>
            {/* <span className="text-muted-dashed ms-1">EGP 150.00</span> */}
          </div>
          <div className="my-1">
            <img src="/assets/star.svg" alt="" className="icon-img-size" />
            <span className="ms-2 bold">4.5</span>
            <span className="text-muted ms-1">(110 {t("reviews")})</span>
          </div>
          <div className="mt-4">
            <Link
              to={`/singleoffer/${offer.id}`}
              className="d-flex justify-content-between bold"
            >
              <button
                className="pop-up2"
                onMouseLeave={() =>
                  setImage("/assets/arrow-circle-right-sml.svg")
                }
                onMouseOver={() =>
                  setImage("/assets/arrow-circle-right-sml2.svg")
                }
              >
                {t("View all upcoming availability")}{" "}
                <img src={image} alt="" className="icon-img-size" />
              </button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
