import { Pagination, Row } from "react-bootstrap";
import Spinner, { SpinnerError } from "../Spinner";
import { useEffect, useState } from "react";

import Offer from "./offer";
import { useSelector } from "react-redux";

export default function Offers({ numPerPage }) {
  const { offers, status } = useSelector((state) => state.offers);
  console.log("offers", offers);

  const [activePage, setActivePage] = useState(1);
  const [viewedOffers, setViewedOffers] = useState([]);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);

  useEffect(() => {
    if (status === "success") {
      setViewedOffers(offers.slice(0, numPerPage));
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [numPerPage, offers, status]);

  let items = [];
  const changePage = (page) => {
    setActivePage(page);
    let offs = offers.slice(numPerPage * (page - 1), numPerPage * page);
    setViewedOffers(offs);
  };

  for (
    let number = 1;
    number <= Math.ceil(offers.length / numPerPage);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => changePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="offers-cards-box">
      {viewedOffers.length > 0 ? (
        <>
          <Row>
            {viewedOffers?.map((offer) => (
              <Offer key={offer.id} offer={offer} />
            ))}
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <Pagination>{items.length > 1 ? items : null}</Pagination>
          </div>
        </>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
